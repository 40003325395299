<template>
  <div>
    <header class="page-header bg-primary">
      <div class="logo-container">
        <img :src="Logo" alt="Start Waves" class="logo" />
      </div>
      <div class="flex">
        <i class="fa fa-gift" aria-hidden="true"></i>
        <span class="page-title">
          SWC Airdrop
        </span>
      </div>
      <button class="menu-toggle-btn" @click="toggleMenu">
        <i class="fa fa-bars fa-2x"></i>
      </button>
      <div class="menu" :class="{ 'menu-open': isMenuOpen }">
        <router-link to="/" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          Home
        </router-link>
        <router-link to="/about-us" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          About Us
        </router-link>
        <router-link to="/contact" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          Contact
        </router-link>
      </div>
    </header>

    <div class="page-container airdrop-container">
      <div class="airdrop-content">
        <section class="intro-section">
          <h1>Welcome to the SWC Airdrop</h1>

          <!-- Video de YouTube agregado aquí -->
          <div class="youtube-video">
            <iframe width="560" height="315" 
              src="https://www.youtube.com/embed/6xFCRrU_PrE?si=Ahp2OPmGYJSUvTyQ" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
          </div>

          <p>
            Start Waves is excited to announce the launch of our SWC token AIRDROP! As part of our mission to bridge the financial gap in Latin America and beyond, we're offering <strong>50 SWC tokens for FREE</strong> to anyone who completes a simple form. Join the future of decentralized finance today!
          </p>
        </section>

        <section class="how-to-claim-section">
          <h2>How to Claim Your 50 SWC</h2>
          <ol>
            <li>Go to the <a href="https://bit.ly/3ARNopE" target="_blank">Airdrop Form</a> on TypeForm.</li>
            <li>Fill in your details and submit the form.</li>
            <li>Receive 50 SWC tokens directly in your wallet!</li>
          </ol>
          <p>
            Make sure you have an Ethereum wallet ready, as the SWC tokens are based on the ERC20 standard on the Ethereum blockchain.
          </p>

          <!-- Sección de los dos videos -->
          <div class="video-section">
            <div class="video-column">
              <h3>How to Create a Wallet on Start Waves</h3>
              <iframe width="100%" height="315" 
                src="https://www.youtube.com/embed/Xzuu4tsccVE?si=HmXPPSFMXjmmy0Sk" 
                title="How to Create a Wallet on Start Waves" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
            <div class="video-column">
              <h3>How to Add the SWC (Start Waves Coin)</h3>
              <iframe width="100%" height="315" 
                src="https://www.youtube.com/embed/z2hm0C49TpA?si=2YpbaGY2a0dsOsFs" 
                title="How to Add the SWC (Start Waves Coin) to Your Wallet" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </section>

        <section class="token-info-section">
          <h2>About SWC (Start Waves Coin)</h2>
          <p>
            SWC is an ERC20 token created to power the Start Waves ecosystem. With a total supply of 1 billion tokens and an initial price of $0.005 USD per token, SWC plays a crucial role in facilitating transactions on our platform.
          </p>
          <ul>
            <li><strong>Blockchain:</strong> Ethereum</li>
            <li><strong>Contract Address:</strong> <a href="https://etherscan.io/token/0x6c9d9d1e1f6cec71d94abfae45a62bc6d30379ed" target="_blank">0x6c9d9d1e1f6cec71d94abfae45a62bc6d30379ed</a></li>
            <li><strong>Supply:</strong> 1,000,000,000 SWC</li>
            <li><strong>Initial Price:</strong> $0.005 USD</li>
          </ul>
        </section>

        <section class="join-section">
          <h2>Join the Start Waves Revolution</h2>
          <p>
            Start Waves is on a mission to reduce the gap in access to banking services in developing countries. By joining our AIRDROP, you're taking the first step in becoming part of a groundbreaking DeFi solution that empowers people with better access to financial services.
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/sw_logo_svg_3.svg';

export default {
  name: 'Airdrop',
  data() {
    return {
      Logo: Logo,
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style>
.airdrop-container {
  padding: 20px;
}

.airdrop-content {
  max-width: 800px;
  margin: 0 auto;
}

.youtube-video {
  margin: 20px 0;
  text-align: center;
}

.intro-section {
  text-align: center;
}

.how-to-claim-section,
.token-info-section,
.join-section {
  margin-top: 40px;
}

.how-to-claim-section ol {
  padding-left: 20px;
}

.how-to-claim-section a {
  color: #1a73e8;
}

/* Estilos para los videos en columnas */
.video-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.video-column {
  flex-basis: 48%;
  margin-bottom: 20px;
}

.video-column h3 {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .video-column {
    flex-basis: 100%;
  }

  .page-title {
    text-align: center;
    display: none;
  }

  .menu-item.disabled {
    pointer-events: none;
    color: #ccc;
    cursor: not-allowed;
  }

  .icon {
    display: none;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 640px) {
  .icon {
    display: none;
  }
}
</style>
